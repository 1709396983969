import { useEffect } from 'react'
import {
  GetServerSideProps,
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next'
import { useRouter } from 'next/router'
import FeedPage from 'components/FeedPage'
import MainPageContainer from 'components/MainPageContainer'
import PartnerMintSuccessFlow from 'components/PartnerMintSuccessFlow'
import {
  CollectionFeedResponse,
  Contract,
  FeaturedProjectResponse,
  FeedId,
  FeedInfo,
  SampleNFTsResponse,
  contractSampleNFTsEndpoint,
  defaultFeedPath,
  defaultFeedRange,
  featuredProjectEndpoint,
  feedEndpoint,
  feedInfo,
  fetcher,
  normalizeQueryValue,
} from 'utils/api'
import { getIsLoggedIn } from 'utils/api/auth/utils'
import { getChainByAlias } from 'utils/chains'
import { isServerReq } from 'utils/next-js'
import { useAccount } from 'utils/useAccount'

type Props = {
  initialFeedId?: FeedId
  initialFeedResponse?: CollectionFeedResponse
  initialSampleNfts?: Record<Contract, SampleNFTsResponse>
  initialFeaturedProjectResponse?: FeaturedProjectResponse
}

export default function IndexPage({
  initialFeedId,
  initialFeedResponse,
  initialSampleNfts,
  initialFeaturedProjectResponse,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { isLoggedIn, isLoadingWallet } = useAccount()
  const router = useRouter()
  const {
    query: { feedId: queryFeedId },
  } = router

  const feedId = queryFeedId as FeedId | undefined

  const currentFeedInfo: FeedInfo | undefined = feedInfo[feedId as FeedId]

  useEffect(() => {
    if (
      router.isReady &&
      (typeof feedId !== 'string' ||
        currentFeedInfo === undefined ||
        (currentFeedInfo.authRequired === true &&
          !isLoadingWallet &&
          !isLoggedIn))
    ) {
      router.replace(defaultFeedPath, undefined, { shallow: true })
    }
  }, [feedId, currentFeedInfo, isLoggedIn, router, isLoadingWallet])

  if (
    typeof feedId !== 'string' ||
    currentFeedInfo === undefined ||
    (currentFeedInfo.authRequired === true && !isLoggedIn)
  ) {
    return null
  }

  return (
    <MainPageContainer tab="minting">
      <PartnerMintSuccessFlow />

      <FeedPage
        key={feedId}
        feedId={feedId}
        initialFeedResponse={
          feedId === initialFeedId ? initialFeedResponse : undefined
        }
        initialSampleNfts={initialSampleNfts}
        initialFeaturedProjectResponse={initialFeaturedProjectResponse}
      />
    </MainPageContainer>
  )
}

const fetchFeedAndSampleNFTs = async (
  ctx: GetServerSidePropsContext,
  feedId: FeedId,
) => {
  const initialFeedResponse: CollectionFeedResponse = await fetcher(
    feedEndpoint(
      feedId,
      normalizeQueryValue(ctx.query.day),
      defaultFeedRange,
      getChainByAlias(normalizeQueryValue(ctx.query.chain))?.id.toString(),
    ),
    ctx,
  )

  // fetch the first 3 contract's sample NFTs
  const initialSampleNfts: Record<Contract, SampleNFTsResponse> = {}
  const toFetch = initialFeedResponse.collections.slice(0, 3)
  try {
    const samples = await Promise.all(
      toFetch.map((c) =>
        fetcher(contractSampleNFTsEndpoint({ contract: c.contract }), ctx),
      ),
    )
    for (let i = 0; i < toFetch.length; i++) {
      initialSampleNfts[toFetch[i].contract] = samples[i]
    }
  } catch (e) {
    // ignore
  }

  return {
    initialFeedResponse,
    initialSampleNfts,
  }
}

const fetchFeaturedProject = async (
  ctx: GetServerSidePropsContext,
): Promise<FeaturedProjectResponse | undefined> => {
  try {
    const initialFeaturedProjectResponse: FeaturedProjectResponse =
      await fetcher(featuredProjectEndpoint, ctx)
    return initialFeaturedProjectResponse
  } catch (e) {
    // ignore
    return undefined
  }
}

export const getServerSideProps: GetServerSideProps<Props> = async (
  ctx: GetServerSidePropsContext,
) => {
  if (!isServerReq(ctx.req)) {
    return {
      props: {},
    }
  }

  const feedId = ctx.params?.feedId as FeedId | undefined

  const info = typeof feedId === 'string' ? feedInfo[feedId] : undefined

  if (feedId === undefined || info === undefined) {
    return {
      redirect: {
        destination: defaultFeedPath,
        permanent: false,
      },
    }
  }

  // ensure we're logged in if this feed requires auth, otherwise redirect to /
  if (info.authRequired) {
    const isLoggedIn = await getIsLoggedIn(ctx)
    if (!isLoggedIn) {
      return {
        redirect: {
          destination: defaultFeedPath,
          permanent: false,
        },
      }
    }
  }

  try {
    const [
      { initialFeedResponse, initialSampleNfts },
      initialFeaturedProjectResponse,
    ] = await Promise.all([
      fetchFeedAndSampleNFTs(ctx, feedId),
      fetchFeaturedProject(ctx),
    ])

    // cache all and free pages
    if (info.cacheHeader !== undefined) {
      ctx.res.setHeader('Cache-Control', info.cacheHeader)
    }

    return {
      props: {
        feedId,
        initialFeedId: feedId,
        initialFeedResponse,
        initialSampleNfts,
        initialFeaturedProjectResponse,
      },
    }
  } catch {
    // let the client fetch the feed
    return { props: { feedId } }
  }
}
