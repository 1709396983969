import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Hex, isHex } from 'viem'
import { PublicClient, usePublicClient } from 'wagmi'
import {
  AddressHash,
  ChainId,
  Contract,
  getChainId,
  getCollection,
} from 'utils/api'
import { isSupportedChainId } from 'utils/chains'
import {
  createPartnerMintSuccessToast,
  createTransactionPendingToast,
} from 'utils/toasts'
import { isContract } from 'utils/web3/helpers'
import { parseTokenIds } from 'utils/web3/transactionHelpers'

const handleMint = async (
  provider: PublicClient,
  collectionContract: Contract,
  txHash: Hex,
) => {
  try {
    const pendingToastId = await createTransactionPendingToast(
      getChainId(collectionContract),
      txHash,
    )

    const [receipt, colRes] = await Promise.all([
      // we do `waitForTransaction` because it works for both already minted
      // and pending transactions
      provider.waitForTransactionReceipt({ hash: txHash }),
      getCollection(collectionContract),
    ])

    const collection = colRes?.collection

    createPartnerMintSuccessToast(
      parseTokenIds(receipt),
      txHash,
      collectionContract,
      collection?.name,
      receipt.from as AddressHash,
      pendingToastId,
    )
  } catch (error) {
    console.error('error getting transaction', error)
  }
}

export default function PartnerMintSuccessFlow() {
  const { query } = useRouter()
  const { collection } = query
  const [chainId, chainIdSet] = useState<ChainId>()

  useEffect(() => {
    if (!isContract(collection)) {
      return
    }

    const id = getChainId(collection)
    if (!isSupportedChainId(id)) {
      return
    }
    chainIdSet(id)
  }, [collection])

  if (chainId === undefined) {
    return null
  }

  return <MintHandler chainId={chainId} />
}

function MintHandler({ chainId }: { chainId: ChainId }) {
  const { asPath, query, replace } = useRouter()
  const [hasHandledMint, hasHandledMintSet] = useState(false)
  const provider = usePublicClient({ chainId })

  useEffect(() => {
    if (hasHandledMint) {
      return
    }

    hasHandledMintSet(true)

    // if the query params have collection and tx, we want to show a toast
    const { collection, tx } = query

    if (!isContract(collection) || !isHex(tx)) {
      return
    }

    handleMint(provider, collection, tx)

    // remove the collection and tx query params and replace
    const newQuery = { ...query }
    delete newQuery.collection
    delete newQuery.tx
    delete newQuery.feedId
    replace({ pathname: asPath.split('?')[0], query: newQuery }, undefined, {
      shallow: true,
    })
  }, [asPath, hasHandledMint, provider, query, replace])

  return null
}
