import React from 'react'
import { useRouter } from 'next/router'
import { NavBar, NavBarTab } from 'components/NavBar'
import { defaultFeedPath } from 'utils/api'

const tabInfo = [
  { id: 'minting', name: 'Minting now' },
  { id: 'offers', name: 'Offers' },
  { id: 'fundrop', name: '!fundrop' },
] as const

export type Tab = typeof tabInfo[number]['id']

type Props = {
  tab: Tab
}

const getTabRoute = (tab: Tab) => {
  switch (tab) {
    case 'minting':
      return defaultFeedPath
    case 'offers':
      return '/offers'
    case 'fundrop':
      return '/fundrop'
  }
}

function TabNav({ tab: selectedTab }: Props) {
  const { push } = useRouter()

  return (
    <NavBar>
      {tabInfo.map(({ id, name }) => (
        <NavBarTab
          key={id}
          isSelected={selectedTab === id}
          onClick={() => push(getTabRoute(id))}
        >
          {name}
        </NavBarTab>
      ))}
    </NavBar>
  )
}

export default React.memo(TabNav)
