import React, { ReactNode } from 'react'
import TabNav, { Tab } from 'components/TabNav'

type Props = {
  tab: Tab
  children: ReactNode
}

export default function MainPageContainer({ tab, children }: Props) {
  return (
    <div className="flex flex-col mb-10 gap-y-7">
      <TabNav tab={tab} />
      {children}
    </div>
  )
}
