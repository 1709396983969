import { SVGAttributes } from 'react'

export default function ChevronLeft(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M9.3334 13.4C8.59702 13.4 8.00008 12.7732 8.00008 12C8.00008 11.2268 8.59702 10.6 9.3334 10.6H10.6667L10.6667 9.48C10.6667 8.55216 11.3831 7.8 12.2667 7.8H13.3334L13.3334 6.4C13.3334 5.6268 13.9303 5 14.6667 5C15.4031 5 16 5.6268 16 6.4C16 7.1732 15.4031 7.8 14.6667 7.8H13.3334L13.3334 8.92C13.3334 9.84784 12.617 10.6 11.7334 10.6H10.6667L10.6667 13.4H11.7334C12.617 13.4 13.3334 14.1522 13.3334 15.08L13.3334 16.2H14.6667C15.4031 16.2 16 16.8268 16 17.6C16 18.3732 15.4031 19 14.6667 19C13.9303 19 13.3334 18.3732 13.3334 17.6L13.3334 16.2H12.2667C11.3831 16.2 10.6667 15.4478 10.6667 14.52L10.6667 13.4L9.3334 13.4Z"
        fill="currentColor"
      />
    </svg>
  )
}
