import React from 'react'
import { useRouter } from 'next/router'
import {
  CollectionFeedResponse,
  Contract,
  FeaturedProjectResponse,
  FeedId,
  SampleNFTsResponse,
  defaultFeedRange,
  featuredProjectEndpoint,
  feedEndpoint,
  isTimeRange,
  normalizeQueryValue,
  useAPIResponse,
} from 'utils/api'
import { getChainByAlias } from 'utils/chains'
import FeedPageView from './FeedPageView'

type Props = {
  feedId: FeedId
  initialFeedResponse?: CollectionFeedResponse
  initialSampleNfts?: Record<Contract, SampleNFTsResponse>
  initialFeaturedProjectResponse?: FeaturedProjectResponse
}

function FeedPage({
  feedId,
  initialFeedResponse,
  initialSampleNfts,
  initialFeaturedProjectResponse,
}: Props) {
  const { query } = useRouter()
  const range = isTimeRange(query.range) ? query.range : defaultFeedRange

  const { data: feedData } = useAPIResponse<CollectionFeedResponse>(
    feedEndpoint(
      feedId,
      normalizeQueryValue(query.day),
      range,
      getChainByAlias(normalizeQueryValue(query.chain))?.id.toString(),
    ),
    range === defaultFeedRange ? initialFeedResponse : undefined,
    {
      refreshInterval: 1000 * 20,
    },
  )

  const { data: featuredProjectResponse } =
    useAPIResponse<FeaturedProjectResponse>(
      featuredProjectEndpoint,
      initialFeaturedProjectResponse,
    )
  const featuredProjects =
    featuredProjectResponse?.featuredProjects ?? undefined

  return (
    <FeedPageView
      feedId={feedId}
      feedData={feedData}
      featuredProjects={featuredProjects}
      initialSampleNfts={initialSampleNfts}
    />
  )
}

export default React.memo(FeedPage)
