import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import ChevronLeft from 'components/icons/ChevronLeft'
import classnames from 'utils/classnames'

type Props = {
  showBackButton?: boolean
  handleBack?: () => void
  children: ReactNode
  className?: string
}

const NavBar = ({
  showBackButton = false,
  handleBack: handleBackProp,
  children,
  className,
}: Props) => {
  const { back, push } = useRouter()
  const [previousURL, setPreviousURL] = useState('')

  useEffect(() => {
    setPreviousURL(document.referrer)
  }, [])

  const handleBack = useCallback(() => {
    if (handleBackProp !== undefined) {
      handleBackProp()
      return
    }

    if (previousURL.includes(window.location.origin)) {
      back()
    } else {
      push('/')
    }
  }, [back, handleBackProp, previousURL, push])

  return (
    <div
      className={classnames(
        'flex mt-2',
        'gap-6 md:gap-9',
        'border-b border-neutral-400 dark:border-neutral-700',
        'text-base md:text-xl',
        'overflow-x-scroll no-scrollbar',
        className,
      )}
    >
      {showBackButton && (
        <button
          type="button"
          className="flex -mr-5 self-center text-sm md:text-base -mt-2 md:-mt-5"
          onClick={handleBack}
          aria-label="Go back"
          title="Go back"
        >
          <ChevronLeft />
        </button>
      )}
      {children}
    </div>
  )
}

export default React.memo(NavBar)
