import React, { ReactNode } from 'react'
import classnames from 'utils/classnames'

type Props = {
  isSelected?: boolean
  onClick?: () => void
  className?: string
  disabled?: boolean
  children: ReactNode
}

const NavBarTab = ({
  isSelected = false,
  onClick,
  className,
  children,
  disabled = false,
}: Props) => {
  return (
    <div>
      {/* div wrapper is so that the text doesn't move around when the border changes */}
      <button
        disabled={disabled}
        onClick={onClick}
        className={classnames(
          'font-bold whitespace-nowrap',
          'pb-2 md:pb-5',
          isSelected && 'border-black dark:border-white border-b-2',
          className,
        )}
      >
        {children}
      </button>
    </div>
  )
}

export default React.memo(NavBarTab)
